.class td,
.class th,
.class thead,
.class tr {
  border: 1px solid #000;
}
.class .table thead th {
  border-bottom: 1px solid #000000;
}
.table-div {
  overflow-x: auto;
}

.class span {
  user-select: none;
}

.iframe_container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.class img {
  max-width: 400px;
  height: auto;
}
.class h1 {
  line-height: 3rem;
}
.class .bg-primary {
  background-color: rgb(150, 217, 238) !important;
}

.class .bg-success {
  background-color: rgb(125, 207, 162) !important;
}
.class .bg-warning {
  background-color: rgb(238, 229, 150) !important;
}

.iframe_doc {
  width: 100%;
  height: auto;
}

.leave_info {
  margin: 100px 0;
  border: 1px solid #000;
  padding: 20px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.leave_info_text span:nth-child(1) {
  color: rgb(255, 43, 43);
  margin-right: 20px;
}
.leave_info_text span {
  font-weight: 900;
  font-size: clamp(0.75rem, 3vw, 1.1rem);
}

.apply_btn {
  background-color: #60b91f;
  color: #fff;
  font-size: clamp(0.7rem, 1.8vw, 1rem);
  padding: 9px 25px;
  border-radius: 9px;
}
.apply_btn:hover {
  color: #fff;
}

.leave_table {
  border-collapse: separate;
  border-spacing: 0 1em;
  font-size: clamp(0.5rem, 1.5vw, 1rem);
}

.leave_table thead {
  background-color: #f7fcfb;
  font-weight: 900;
  border: 1px solid rgb(168, 168, 168);
}
.leave_table td {
  font-weight: 500;
  margin-top: 50px;
  border-bottom: 1px solid rgb(209, 209, 209);
}

.leave_table td:last-child,
.leave_table th:last-child {
  border-right: 1px solid rgb(209, 209, 209);
}
.leave_table td:first-child,
.leave_table th:first-child {
  border-left: 1px solid rgb(209, 209, 209);
}

.leave_top {
  font-size: clamp(1.9rem, 2.2vw, 4rem);
}
.leave_top_title {
  font-size: clamp(1.3rem, 2.1vw, 4rem);
}
.leave_type,
.leave .dropdown-menu {
  background-color: #f7fcfb;
}

.leave form {
  margin-top: 30px;
  padding: 0 50px;
}
.leave .form-group {
  margin: 50px 0;
}
.leave .dropdown-toggle {
  min-width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.leave form label {
  font-size: clamp(1rem, 1.3vw, 2rem);
}

.leave_range {
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: 20px;
}
.day_picker .MuiInputBase-root > input {
  border: 1.9px solid #cfcfcf;
  border-radius: 0;
  background-color: #f7fcfb;
}
.leave_range .MuiGrid-container {
  margin-left: 0;
}
.leave_desc {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 200px;
}
.leave_desc_input {
  border: 1.9px solid #cfcfcf;
  border-radius: 20px;
  padding: 12px;
}

@media only screen and (max-width: 1200px) {
  .leave_range {
    width: 100%;
  }
}

@media only screen and (max-width: 880px) {
  .leave {
    padding: 0 30px;
  }
}
@media only screen and (max-width: 780px) {
  .leave {
    padding: 0 10px;
  }
  .leave_info {
    padding: 10px 20px;
  }
  .leave form {
    padding: 0;
  }
}
@media only screen and (max-width: 500px) {
  .leave_desc {
    width: 100%;
    height: 150px;
  }
}

.month_total {
  background-color: var(--theme_color);
  padding: 20px 30px;
  margin: 55px 0 55px 0;
  width: 250px;
  justify-content: flex-start;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 15px;
}

.month_text {
  font-size: clamp(1rem, 1.5vw, 1.2rem);
}
.month_price {
  margin-top: 5px;
  font-size: clamp(1.5rem, 2vw, 1.9rem);
}

.earn_type {
  height: auto;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.earn_type div:first-child {
  border-radius: 20px 0 0 20px;
}
.earn_type div:last-child {
  border-radius: 0px 20px 20px 0px;
}
.earn_type_item {
  margin: 20px 0 20px 0;
  border: 1px solid var(--theme_color);
  padding: 20px;
  font-weight: 900;
  color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 100px;
}

.earn_price {
  font-size: clamp(0.9rem, 1.5vw, 2.2rem);
}
.earn_text {
  font-size: clamp(0.5rem, 1.2vw, 1.9rem);
}

.earn_chart {
  margin-top: 90px;
}
@media screen and (max-width: 1290px) {
  .earn_type_item {
    flex: 1 0 calc(50% - 10px);
  }
  .earn_type div:nth-child(2) {
    border-radius: 0 20px 20px 0;
  }
  .earn_type div:nth-child(3) {
    border-radius: 20px 0 0 20px;
  }
  .earn_type div:nth-child(4) {
    border-radius: 0 20px 20px 0;
  }
  .earn_type div:last-child {
    border-radius: 20px 20px 20px 20px;
  }
}
@media screen and (max-width: 1000px) {
  .earn_type_item {
    flex: 1 0 calc(50% - 10px);
  }
}

@media screen and (max-width: 950px) {
  .earning {
    padding: 0 20px;
  }
}

@media screen and (max-width: 780px) {
  .month_total {
    width: 200px;
  }
  .earning {
    padding: 0 0;
  }
}

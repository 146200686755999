.ticket_section {
  margin: 40px 0 0 0;
  padding: 40px 0;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 1px 5px 1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  -moz-box-shadow: rgba(50, 50, 93, 0.25) 0px 1px 5px 1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  -webkit-box-shadow: rgba(50, 50, 93, 0.25) 0px 1px 5px 1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.ticket_section h1 {
  font-size: clamp(1.5rem, 2vw, 3rem);
}

.ticket_top h1 {
  padding: 20px 40px;
}
.ticket_info h1 {
  padding: 20px 0;
}
.ticket_info {
  padding: 0 40px;
}
.ticket_top {
  background-color: rgba(204, 204, 204, 0.6);
}

.ticket_section table,
.ticket_section th,
.ticket_section td,
.ticket_section thead {
  border: 1px solid #000;
  padding: 1em;
  font-size: clamp(0.7rem, 1.5vw, 1rem);
}

.ticket_section th,
.ticket_section td {
  border: 1px dashed #000;
}

.ticket_section tr td {
  padding: 25px 0;
}

.ticket_section button {
  margin: 20px 0;
}

.ticket_section form {
  margin-top: 90px;
  width: 60%;
  font-weight: 900;
}

.ticket_desc {
  display: flex;
  flex-direction: column;
}
.ticket_desc textarea,
.ticket_desc input {
  border: 1.9px solid #000000;
  border-radius: 10px;
  padding: 12px;
}

.ticket_desc input:focus {
  outline: none;
}

.ticket_section .attach {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  font-size: clamp(0.9rem, 1.5vw, 1rem);
}
.ticket_section .attach {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: clamp(0.9rem, 1.5vw, 1rem);
}
.attach label {
  padding: 10px;
  background: rgb(182, 182, 182);
  display: table;
  color: rgb(0, 0, 0);
  cursor: pointer;
}

input[type="file"] {
  display: none;
}
@media screen and (max-width: 780px) {
  .ticket_section {
    width: 100%;
  }

  .ticket_info {
    padding: 0 15px;
  }
  .ticket_section form {
    width: 80%;
  }
}

@media screen and (max-width: 500px) {
  .ticket_section form {
    width: 100%;
  }
}

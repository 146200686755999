.dashboard_title {
  font-size: clamp(1.3rem, 2vw, 2rem);
}
.attach_file {
  transform: rotate(45deg);
}
.noti_desc {
  word-wrap: break-word;
}

.trial_view {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.trial_item {
  margin: 20px 10px 20px 10px;
  height: 160px;
  border-radius: 10px;
  cursor: pointer;
  background: #6b0080;
  padding: 20px;
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 100px;
}

.trial_num {
  font-size: clamp(3rem, 1vw, 5rem);
  font-weight: 700;
}
.trial_title {
  font-size: clamp(0.75rem, 3vw, 1rem);
  font-family: "Poppins";
  text-align: center;
}

.MuiGrid-container {
  width: 150px !important;
  margin-left: 2rem;
}
.MuiInputBase-root > input {
  border: 1.9px solid #6b0080;
  border-radius: 20px;
  padding: 6px 10px;
  font-weight: 900;
  cursor: pointer;
  text-align: center;
}
.MuiPickersToolbar-toolbar {
  background-color: #6b0080 !important;
}
.MuiInput-underline::after,
.MuiInput-underline::before {
  content: none !important;
}

.upcoming_radio {
  border: 1px solid #6b0080;
  padding: 4px 30px;
}

.upcoming_radio_active {
  background-color: #6b0080;
  color: #ffffff;
}

.upcoming_radio_active:hover {
  color: #ffffff;
}
.feedback_item label {
  font-weight: 900;
  margin-bottom: 1rem;
}
.feedback_item {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  margin: 20px 0;
}
.feedback_item textarea {
  width: 80%;
  height: 150px;
  padding: 20px;
  border: 2px solid rgb(201, 201, 201);
  border-radius: 10px;
}

.feedback_item .MuiBox-root {
  margin-bottom: 0;
}

.radio_label {
  font-weight: 100 !important;
}

.feedback_radio input {
  cursor: pointer;
}
.radio_yes input[type="radio"]:checked:after {
  background-color: #00bb19;
}

.radio_no input[type="radio"]:checked:after {
  background-color: #ff0202;
}

.feedback_radio input[type="radio"]:checked:after {
  cursor: pointer;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -3px;
  left: -1px;
  position: relative;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}
.dashboard_top {
  margin-bottom: 50px;
}
.bellicon {
  width: 45px;
  height: 45px;
  border: 1px solid rgba(209, 209, 209, 1);
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}
.noti_no {
  color: #fff;
  font-weight: 900;
  background-color: red;
  font-size: 0.7rem;
  position: absolute;
  padding: 3px 5px;
  top: -4px;
  right: -8px;
  border-radius: 50%;
}
.hide {
  display: none;
}
.notification_view {
  position: absolute;
  right: 100px;
  margin: 50px 10px;
  width: 350px;
  max-height: 500px;
  overflow-y: scroll;
  padding: 10px;
  border-radius: 5px;
  z-index: 1;
  background-color: #f8f8f8;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 5px 5px 1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  -moz-box-shadow: rgba(50, 50, 93, 0.25) 0px 5px 5px 1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  -webkit-box-shadow: rgba(50, 50, 93, 0.25) 0px 5px 5px 1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.notification_view h5 {
  font-size: 1rem;
}
.notification_view p {
  font-size: 0.75rem;
}
.noti_date {
  font-size: 0.7rem;
}
.dashboard_top img {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.dashboard_top h1 {
  font-size: clamp(1.5rem, 2.1vw, 2.2rem);
}
@media screen and (max-width: 1275px) {
  .trial_item {
    flex: 1 0 calc(35% - 10px);
  }
}

@media screen and (max-width: 780px) {
  .notification_view {
    right: 10px;
    margin: 50px 10px;
    width: 350px;
  }
}
@media screen and (max-width: 500px) {
  .feedback_item textarea {
    width: 100%;
    height: 100px;
    padding: 20px;
    border: 1px solid #000;
  }
  .notification_view {
    right: 10px;
    margin: 50px 10px;
    width: 300px;
  }
}

.trial_taken_view svg {
  cursor: pointer;
}

.tk_btn {
  color: #fff;
  font-size: clamp(0.7rem, 2vw, 1rem);
  padding: 9px 25px;
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tk_btn.sm {
  padding: 3px 5px;
}

.tk_btn span {
  margin: 0 10px;
}

.tk_dropdown .dropdown button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1.0498px solid #cccccc;
}

.tk_dropdown .dropdown-toggle::after {
  content: none;
}
.tk_dropdown .dropdown-menu {
  border: 1px dashed rgb(158, 158, 158);
  transform: none !important;
}
.tk_dropdown .dropdown-menu .dropdown-item {
  border: 1px dashed rgb(185, 185, 185);
}
.tk_dropdown .dropdown-menu .dropdown-item:hover {
  cursor: pointer;
  color: #fff;
  background-color: var(--theme_color);
}

.trial_info {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 1px 5px 1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  -moz-box-shadow: rgba(50, 50, 93, 0.25) 0px 1px 5px 1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  -webkit-box-shadow: rgba(50, 50, 93, 0.25) 0px 1px 5px 1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.trial_info_box {
  padding: 19px 20px;
}
.trial_info {
  font-size: clamp(0.7rem, 2vw, 1rem);
  font-weight: 900;
}
.expand_text {
  margin: 0 15vw;
  padding-bottom: 20px;
  text-align: start;
  font-weight: 500;
  font-size: clamp(0.5rem, 1.8vw, 1rem);
}

.month_title {
  font-size: clamp(1.3rem, 2vw, 2rem);
}

.back_title {
  margin-left: 50px;
  font-weight: 900;
  font-size: clamp(1.7rem, 2.4vw, 2rem);
}
.success_modal .continue {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  padding: 10px;
  color: #fff;
  border-radius: 4px;
  box-shadow: 1px 7px 20px -3px rgba(0, 0, 0, 0.55);
  -webkit-box-shadow: 1px 7px 20px -3px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 1px 7px 20px -3px rgba(0, 0, 0, 0.55);
}
.modal_content.success_modal .sucess_tick_img {
  width: 90px;
}
.modal_content.success_modal .success_clr {
  color: rgb(98, 207, 76);
}
.modal_content.success_modal .error_clr {
  color: rgb(197, 61, 61);
}
.modal_content.success_modal .msg_text {
  font-size: 1.5rem;
  font-weight: 900;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}
@media all and (min-width: 780px) {
  .modal.full_view {
    padding: 15% 0 0 0;
  }
  .modal.full_view .success_modal {
    width: 40% !important;
  }
}
.modal_content.success_modal .success_msg {
  color: #353535;
  font-size: 1rem;
  margin-bottom: 1.5rem;
  font-weight: 900;
}
.modal_content.success_modal {
  width: 50%;
  position: relative;
  box-shadow: -2px 2px 23px -4px rgba(0, 0, 0, 0.55);
  -webkit-box-shadow: -2px 2px 23px -4px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: -2px 2px 23px -4px rgba(0, 0, 0, 0.55);
}
@media screen and (max-width: 780px) {
  .trial_status {
    padding: 5px 15px;
  }
}
.error_btn_clr {
  background-color: rgb(255, 27, 27);
}
.success_btn_clr {
  background-color: rgb(98, 207, 76);
}

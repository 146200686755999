.course_title {
  font-size: clamp(1.2rem, 2vw, 4rem);
  color: var(--theme_color);
  display: block;
}

.detail_back svg {
  position: fixed;
  margin-top: 20px;
}
.course_details_container {
  margin: 0 4vw;
  padding: 20px;
  width: 100%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 1px 5px 1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  -moz-box-shadow: rgba(50, 50, 93, 0.25) 0px 1px 5px 1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  -webkit-box-shadow: rgba(50, 50, 93, 0.25) 0px 1px 5px 1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.course_detail h1 {
  user-select: none;

  font-size: clamp(1.7rem, 2vw, 2rem);
}
.course_detail h4 {
  user-select: none;

  font-size: clamp(1rem, 1.5vw, 1.5rem);
}
.course_detail p {
  user-select: none;
  font-size: clamp(0.8rem, 1.3vw, 1.3rem);
}
@media only screen and (max-width: 780px) {
  .detail_back svg {
    top: 100px;
  }
  .course_details_container {
    margin-top: 40px;
  }
}

.MyRefferals__heading {
  font-weight: 900;
}

.table_body_items {
  font-weight: 400;
  padding: 12px 10px;
  display: flex;
  justify-content: space-between;
}

.MyReferals__table--row:nth-child(odd) {
  background-color: #f4f4f4;
}
.table_header {
  background-color: #dddd;
}
.MyReferals__completed {
  background-color: #50048c;
  color: #fff;
}

.status_icons {
  width: 25px;
  margin-right: 20px;
}
.status_icons > img {
  width: 100%;
}
.referal__complete {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: bottom;
  width: 100%;
  margin-left: auto;
  padding-bottom: 1rem;
}
.referal__completeContainer {
  background-color: #7600e8;
  width: fit-content;
  font-weight: 900;
  display: inline-block;
  padding: 5px 7px;
  /* display: flex; */
  position: relative;
  border-radius: 5px;
}
.referal__completeContainer p,
.referal__completeContainer div {
  display: inline-block;
}
.referal__completeContainer p {
  margin-top: 10px;
}
.referal__completeContainer > .status_icons {
  width: 40px;
  margin-top: -50px;
  position: absolute;
  top: 35px;
}
.referal__completeContainer p {
  margin: 0 0 0 40px;
}

.referal__complete__redeem__btn {
  background-color: #7600e8;
  font-weight: 900;
  color: white;
  padding: 4.5px 7px;
  border-radius: 5px;
  margin-left: 1rem;
  height: fit-content;
  display: inline-block;
  margin-right: 2rem;
}

@media (max-width: 500px) {
  .referal__complete {
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
  }
  .referal__completeContainer {
    font-size: 0.9rem;
  }
  .referal__completeContainer > .status_icons {
    width: 35px;
    margin-top: -45px;
  }
}

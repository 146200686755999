.back_title {
  font-size: clamp(1.1rem, 2.4vw, 2rem);
}

.tech_support li {
  margin: 50px 0;
}

.password_change {
  width: 50%;
  margin: 40px 80px;
}
.change_password_form .form-group {
  display: flex;
  flex-direction: column;
}

.change_password_form input {
  border: none;
  border-bottom: 1px solid #000000;
  padding: 5px 10px;
  outline: none;
}

@media screen and (max-width: 780px) {
  .password_change {
    width: 50%;
    margin: 40px 20px;
  }
}
@media screen and (max-width: 500px) {
  .password_change {
    width: 90%;
    margin: 40px 20px;
  }
}

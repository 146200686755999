.calendar {
  overflow-x: auto;
}
.month_icon {
  font-size: clamp(1.5rem, 2vw, 2rem);
  color: var(--theme_color);
}
.header_month {
  display: flex;
  align-items: center;
}
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}
.current_month {
  margin: 0 15px;
  color: var(--theme_color);
  font-size: clamp(1rem, 2vw, 2rem);
}
.calendar .header {
  text-align: center;
  color: rgb(58, 58, 58);
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  margin: 0 0 40px 0;
}

.header_dropdown {
  position: relative;
  font-family: Arial;
}

.header_dropdown select {
  border: 1px solid var(--theme_color);
  border-radius: 20px;
  padding: 3px 24px;
  color: var(--theme_color);
  font-weight: 900;
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 97%;
  background-position-y: 5px;
}
.header_dropdown select option {
  margin-left: 20px;
}

.header_dropdown select:focus {
  border: 1px solid var(--theme_color);
  border-radius: 20px;
  outline: none;
}

.calander_item {
  color: #000;
  font-weight: 900;
  font-size: 0.9rem;
}
.calander_item::after {
  border: 1px dashed #000;
}

table {
  text-align: center;
}
td {
  width: 12.5%;
  position: relative;
}
td:after {
  content: "";
  display: block;
}
td div {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.table-bordered {
  border: 1px solid var(--theme_color);
}
.slot_locked {
  background-color: #09db31;
  color: white !important;
}
.slot_cancel {
  background-color: #ff0000;
  color: white !important;
}
.slot_available {
  background-color: #43dcff;
  color: white !important;
}
.slot_booked {
  background-color: #3f51b5;
  color: white !important;
}
.slot_paid_booked {
  background-color: #81007f;
  color: white !important;
}
.table-bordered th {
  border: 1px solid #fff;
  color: #fff;
  background-color: #8900a5;
  font-weight: 100;
}

.table-bordered tr {
  border: 1px solid var(--theme_color);
}

.table-bordered .day {
  border: 1px solid var(--theme_color);
  cursor: pointer;
  vertical-align: middle;
  font-weight: 900;
  color: var(--theme_color);
}
.week_table .week {
  font-weight: 900;
  background-color: #fff;
  color: var(--theme_color);
  border: 1px solid var(--theme_color);
}
.week_head {
  display: flex;
  flex-direction: column;
}
.table-bordered .timing,
.table-bordered .head_timing {
  vertical-align: middle;
  align-self: center;
  font-size: clamp(0.5rem, 2vw, 0.8rem);
  border-left: hidden;
  border-bottom: hidden;
  border-right: 1px solid var(--theme_color);
  background-color: #fff;
  font-weight: 900;
  color: #000;
}

.day_names {
  color: var(--theme_color);
}

.today span {
  background-color: var(--theme_color);
  color: #fff;
  display: block;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  margin: auto;
  color: #fff;
  line-height: 25px;
  text-align: center;
}

.not_in_month {
  cursor: not-allowed !important;
}
.not_in_month span {
  visibility: hidden;
  cursor: not-allowed !important;
}
.before span {
  color: #e14aff;
}
.before {
  cursor: not-allowed !important;
}
.day_calendar {
  display: flex;
  align-items: center;
}
.day_timings {
  font-size: clamp(0.6rem, 2vw, 1.2rem);
  font-weight: 900;
}
.day_space {
  height: 10vh;
  border: 1px solid var(--theme_color);
  font-size: clamp(1.4rem, 4vw, 1.5rem);
  color: var(--theme_color);
}
.day_calendar .selected {
  background-color: rgb(32, 240, 32);
}
.newSelected {
  background-color: blue;
}
.booked_slot {
  background-color: lightgreen;
}
. .week_date {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 900;
}
.week_date_center {
  width: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dayDate {
  display: flex;
  align-items: center;
  justify-content: center;
}
.save_button {
  color: white;
  border: 1px solid purple;
  background-color: purple;
  padding: 3px;
  width: 80px;
  height: 50px;
  border-radius: 5px;
  margin-left: 80px;
}
.booked_slot_data {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  overflow: hidden;
  min-width: 100px;
}
.booked_slot_data h6 {
  font-size: 0.8rem;
  line-height: 80%;
  word-wrap: ;
}
.disable_day {
  cursor: not-allowed;
  color: #e14aff !important;
}

@media screen and (min-width: 730px) {
  .table-bordered td {
    height: 20px;
  }

  .calendar .header {
    margin: 0 10px 30px 10px;
  }
}

@media screen and (min-width: 1040px) {
  .table-bordered td {
    height: 20px;
  }
}

@media screen and (min-width: 1240px) {
  .table-bordered td {
    height: 20px;
  }
}

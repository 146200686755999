.course_page_title {
  color: var(--theme_color);
  margin: 20px 20px 200px 20px;
  font-size: clamp(1.3rem, 1.9vw, 1.9rem);
  font-weight: 900;
}
.course_level {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.courses_level {
  border: 5px solid var(--theme_color);
  padding: 1vw 50px;
  width: 30.33%;
  margin: 20px 5px;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex: 1 1 30%;
  align-items: center;
  justify-content: center;
}
.level_active {
  color: #ffffff;
  background-color: #6b0080;
}
.courses_level:hover {
  color: #ffffff;
  background-color: #6b0080;
}
.level_text {
  font-weight: 900;
  font-size: clamp(1rem, 2vw, 3rem);
  margin-right: 20px;
}

.courses_level svg path {
  stroke: #ffffff;
}
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");

.course_container {
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
}

.course_info {
  width: 25%;
  height: auto;
  border: 2px solid #6b0080;
  border-radius: 20px;
  padding: 10px 0;
  margin: 20px 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1 20%;
}
.course_info img {
  width: 72px;
  height: auto;
}
.course_name {
  text-align: center;
  font-weight: 900;
  margin-top: 10px;
  font-size: clamp(0.8rem, 1vw, 1.5rem);
}

.course_info:hover {
  color: #ffffff;
  background-color: var(--theme_color);
}

.courses_icon svg {
  width: 4vw;
  height: auto;
}

@media screen and (max-width: 1240px) {
  .course_info {
    margin: 20px 15px;

    flex: 1 0 calc(20% - 10px);
  }
}
@media screen and (max-width: 1000px) {
  .course_info {
    margin: 20px 10px;
  }
}
@media only screen and (min-width: 782px) and (max-width: 950px) {
  .courses_level {
    border-radius: 15px;
    border: 3px solid var(--theme_color);
  }
  .level_text {
    margin-right: 10px;
  }
}
@media only screen and (max-width: 780px) {
  .course_level {
    margin: 0 0;
  }
  .courses_level {
    padding: 2vw 60px;
  }
  .courses_icon svg {
    width: 7vw;
    height: auto;
  }
}

@media only screen and (max-width: 500px) {
  .course_info {
    width: 33%;
    margin: 20px 5px;
    flex: 1 1 33%;
  }
  .course_name {
    font-size: 0.75rem;
    padding: 0 5px;
  }
  .courses_level {
    padding: 1vw 15px;
    border-radius: 10px;
    border: 3px solid var(--theme_color);
  }
  .level_text {
    margin-right: 10px;
  }
}

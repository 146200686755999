.setting_section ul li {
  font-weight: 900;
  padding: 20px;
  border-bottom: 1px dashed rgb(145, 145, 145);
}

.setting_section .logout_btn {
  color: #fff;
  background-color: rgb(50, 175, 50);
  padding: 10px 20px;
  border-radius: 5px;
}
.setting_social_icon img {
  cursor: pointer;
}
.logout_btn svg {
  margin-right: 10px;
}

.setting_social_icon img {
  width: 40px;
  height: auto;
  margin-right: 10px;
}

@media screen and (min-width: 1200px) {
  .setting_section {
    padding-right: 300px;
  }
}

@media screen and (max-width: 780px) {
  .setting_social_icon img {
    margin-right: 5px;
  }
}

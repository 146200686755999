.profile p,
.profile span {
  font-size: clamp(0.85rem, 1.1vw, 1rem);
}

.profile_info_box {
  border: 1px solid #000;
  border-radius: 20px;
  max-height: 750px;
  min-width: 210px;
  max-width: 350px;
  margin-bottom: 60px;
}
.profile_text {
  padding-right: 60px;
  word-wrap: break-word;
}
.profile_pic_option {
  width: 60%;
}
.profile_pic {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 3px solid #6b0080;
  margin: 30px auto;
  background-size: cover;
  object-fit: cover;
}

.profile_edit .profile_pic {
  width: 200px;
  height: 200px;
  border: none;
}
.teacher_badge {
  width: 210px;
  height: 230px;
}
.teacher_badge_sm {
  width: 180px;
  height: 200px;
}

.badge_item {
  background-color: #e5e5e5;
  padding: 3px 10px;
  border-radius: 5px;
  margin: 0 20px 10px 0;
}
.badge_item svg {
  float: right;
}
.smbadge_section {
  width: 100%;
}
.progress_next_icon {
  font-size: 3.5rem;
  color: #03b2cb;
}
.scrollable {
  overflow: scroll;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.scrollable::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollable {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.certificate {
  padding: 30px;
  width: 250px;
  background-color: #e5e5e5;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.certificate span {
  font-size: 0.8rem;
}
.certificate img {
  width: 200px;
  height: auto;
  background-color: #000;
  background-size: cover;
  object-fit: cover;
}

.p_60 {
  padding: 0 60px;
}

.profile_edit_radio ul {
  list-style: none;
}
.profile_edit_radio li {
  display: inline-block;
}
.profile_edit_radio input {
  visibility: hidden;
}
.profile_edit_radio label {
  cursor: pointer;
  border: 1px solid #000;
  border-radius: 20px;
  padding: 3px 10px;
  font-size: 0.9rem;
}
.profile_edit_radio input:checked + label {
  color: #fff;
  background: var(--theme_color);
}
.profile_edit_input > .tk_dropdown label {
  font-weight: 900;
}

.profile_edit_input .badge_item {
  padding: 5px 5px 3px 10px;
}
.profile_edit_input .badge_item svg {
  float: right;
}
.profile_edit_input label {
  font-weight: 900;
}
.profile_edit_input .PhoneInput {
  border-radius: 10px;
  background-color: #e5e5e5;
  border: none;
  padding: 0 10px;
}

.PhoneInputCountrySelectArrow {
  height: 20px;
  border-top-width: 0;
  width: 5px !important;
  border-bottom-width: 3px !important;
  border-left-width: 0;
  border-right-width: 3px !important;
  opacity: 1 !important;
}

.profile_edit_input .tk_dropdown .dropdown-menu {
  width: 100%;
}
.profile_edit_input .MuiInputBase-root > input {
  border-radius: 10px;
  background-color: white;
}
.profile_edit_input .MuiGrid-container {
  margin-left: 0;
  width: 100% !important;
  display: flex;
  flex-direction: column;
}

.upload_pic {
  cursor: pointer;
  color: #fff;
  font-size: clamp(0.7rem, 2vw, 1rem);
  padding: 9px 25px;
  border-radius: 9px;
  display: flex;
  align-items: center;
  background-color: rgb(0, 249, 189);
}

.upload_pic label {
  font-weight: 900;
  cursor: pointer;
  margin-bottom: 0;
}

@media screen and (max-width: 1120px) {
  .smbadge_section {
    justify-content: center;
    flex-direction: column;
  }
}
@media screen and (max-width: 1000px) {
  .p_60 {
    padding: 0 20px;
  }
}
@media screen and (min-width: 785px) and (max-width: 995px) {
  .teacher_badge_sm {
    width: 90px;
    height: 90px;
  }
}

@media screen and (max-width: 500px) {
  .teacher_badge_sm {
    width: 90px;
    height: 90px;
  }
}

.handbook_container {
  margin-left: 3vw;
}

.handbook_topic_list {
  font-weight: 900;
  margin-bottom: 0;
}
.handbook_topic_list li {
  display: inline-block;
  margin-right: 0.9rem;
}

.handbook_topic {
  margin-top: 15px;
  padding: 25px 20px;
  background-color: #f8f4f4;
}
.handbook_desc_container {
  box-shadow: rgb(50 50 93 / 15%) 0px 1px 5px 1px,
    rgb(0 0 0 / 30%) 0px 1px 3px -1px;
  -moz-box-shadow: rgba(50, 50, 93, 0.15) 0px 1px 5px 1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  -webkit-box-shadow: rgb(50 50 93 / 15%) 0px 1px 5px 1px,
    rgb(0 0 0 / 30%) 0px 1px 3px;
}

.handbook_topic {
  font-size: clamp(0.8rem, 2vw, 1rem);
}
.handbook_topic:hover,
.handbook_active {
  color: #fff;
  cursor: pointer;
  background-color: var(--theme_color);
}
.handbook_desc_container {
  height: auto;
  padding: 20px 30px;
}

.handbook_desc_container h1 {
  font-size: clamp(1.5rem, 2.2vw, 1.6rem);
  font-weight: 900;
  margin-bottom: 1rem;
}
.handbook_desc_container h5{
  font-weight: 900;
}

.handbook_desc_container ul {
  margin: 20px 0;
}

.handbook_desc_container li {
  padding: 10px 0;
  /* border-bottom: 1px solid #000; */
  font-weight: 900;
}
.handbook_desc_container li span {
  /* padding: 10px 0; */
  /* border-bottom: 1px solid #000; */
  font-weight: normal;
}

.topic_question {
  font-size: clamp(1.2rem, 2vw, 1.3rem);
  /* font-weight: 900; */
}
.handbook_desc_container li svg {
  min-width: 1.2rem;
}

.handbook_desc_container p {
  font-size: clamp(0.7rem, 1.7vw, 1rem);
}

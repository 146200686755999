.calendar_img {
  width: 30px;
  height: 30px;
}
.trial_taken_title {
  font-weight: 900;
  color: var(--theme_color);
}
.modal_error {
  color: red;
}
.color {
  color: var(--theme_color);
}

.modal {
  display: none;
  position: fixed;
  z-index: 100;
  padding: 15% 0 0 250px;
  width: 100%;
  overflow: auto;
  background-color: rgba(49, 49, 49, 0.349);
}

.modal_content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  width: 80%;
  font-weight: 900;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 1px 5px 1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  -moz-box-shadow: rgba(50, 50, 93, 0.25) 0px 1px 5px 1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  -webkit-box-shadow: rgba(50, 50, 93, 0.25) 0px 1px 5px 1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.close {
  color: #000;
  display: block;
  opacity: 1 !important;
  cursor: pointer;
}

.status_modal_btn button {
  margin-top: 20px;
  display: block;
}
.show_modal {
  display: block;
}
.star_rating_container {
  display: flex;
  width: 300px;
  justify-content: space-between;
}
@media screen and (max-width: 780px) {
  .calendar_img {
    width: 20px;
    height: 20px;
  }
  .modal {
    padding: 30vw 0 10px 0;
  }
}

@media screen and (max-width: 500px) {
  .modal {
    padding: 50vw 0 10px 0;
  }
}

.password_modal {
  display: flex;
  flex-direction: column;
  width: auto;
}
.password_modal_form {
  margin-top: 30px;
}
.password_modal input {
  border: 1.9px solid #000000;
  padding: 5px;
}
.password_modal input:focus {
  border: 1.9px solid #000000;
}

@media all and (min-width: 500px) {
  .password_modal {
    width: 300px;
  }
}
@media all and (min-width: 1180px) {
  .password_modal {
    width: 500px;
  }
}

.color_details {
  margin: 2.5rem 5vw;
}

.color_details div {
  display: flex;
  align-items: center;
}

.color_details p.color_item {
  color: white;
  padding: 8px 10px;
  border-radius: 9px;
  margin-right: 0.5rem;
  width: 8rem;
  text-align: center;
}

.color_details p{
  margin-bottom: 0.5rem;
}

